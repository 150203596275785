import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const verhaal = ({ data }) => {
  console.log(data.allInstagramContent.edges)
  const instagramPhotos = data.allInstagramContent.edges
  return (
    <Layout language="nl" en="our-story" nl="ons-verhaal">
      <SEO
        title="Ons Verhaal"
        keywords={[`valta`, `lamp`, `licht`, `over ons`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Ons Verhaal</h1>
      </div>
      <article className="gridFullWidth">
        <div className="articleContent">
          <h2>Valta Studio is een Nederlands ontwerpbureau uit Groningen.</h2>
          <p>
            Onze filosofie is simpel: Wij maken mooie en functionele producten,
            met zoveel mogelijk lokale en milieuvriendelijke materialen.
          </p>
          <p>
            Hiervoor gebruiken zowel moderne technieken als 3D printers als
            traditionele houtbewerkingsmethoden.
          </p>
          <p>
            Dankzij deze technieken kunnen we snel onze ontwerpen in de praktijk
            brengen en verbeteren, en ze gewoon hier in Nederland produceren.
          </p>
          <p>
            Je kunt al onze ontwikkelingen en nieuwe ontwerpen volgen via{' '}
            <a href="https://instagram.com/valtastudio">Instagram</a>.
          </p>
        </div>
        <aside>
          <div className="scrollingMenu"></div>
        </aside>
      </article>
      <div className="articleHero gridFullWidth">
        <h1>Volg ons op Instagram</h1>
      </div>
      {instagramPhotos.map(instagramPhoto => {
        const photo = instagramPhoto.node
        return (
          <>
            {photo.localImage.childImageSharp && (
              <a
                className="grid4 instaPhoto"
                href="https://instagram.com/valtastudio"
              >
                <Img
                  fluid={photo.localImage.childImageSharp.fluid}
                  // key={singleProduct.node.images[0].id}
                  // alt={singleProduct.node.title}
                />
              </a>
            )}
          </>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query InstagramNL {
    allInstagramContent {
      edges {
        node {
          caption
          media_url
          localImage {
            childImageSharp {
              fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default verhaal
